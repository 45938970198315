const messages = [
  {
    id: 0,
    title: 'Join the next generation of jobhunters and register to our platform!',
    subtitle: 'Increase your chances and let employers find you'
  },
  {
    id: 1,
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    subtitle: 'Suspendisse vitae dolor quis est venenatis varius. Morbi ut quam lacinia',
  },
  {
    id: 2,
    title: 'Suspendisse viverra arcu quis diam mattis volutpat',
    subtitle: 'Donec risus dolor, porta hendrerit dui sed, tristique lobortis ante'
  }
];

export default messages;