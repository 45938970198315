export const faq = [
  {
   "id": 1,
   "question": "What are the services do you offer",
   "answer": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates corporis vitae tempora quod provident tenetur culpa dolore facere? Earum, dolor?"
  },
  {
   "id": 2,
   "question": "What are our preferred method of payment",
   "answer": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Architecto iusto veniam eveniet labore impedit nam"
  },
  {
   "id": 3,
   "question": "Are your services beginners friendly",
   "answer": "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, sed. Dolores,"
  },
  {
   "id": 4,
   "question": "What how does it take to upgrade a package",
   "answer": "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, sed. Dolores, sequi."
  },
  {
   "id": 5,
   "question": "Where are your offices located around the world",
   "answer": "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, sed. Dolores, sequi."
  }
 ]